
import "./App.css";
import { useState } from "react";

function App() {

  const[playSound, set_playsound] =  useState(false);



  const agoTag = <span className="ago" 
  
  // style={{display:"none"}}
  
  ></span>;
  return (
    <>
      <main class="flex min-h-screen flex-col items-center justify-between  bg-white text-black">


   
      

        <nav
          class="bg-white dark:bg-transparent fixed w-full z-20 top-0 left-0 "
          style={{ backgroundColor: "rgba(127, 89, 75, 0.455)" }}
   
        >
          <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a class="flex items-center" href="#">
              <div class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white flex">
                Nut &amp; Suay
                <div class="relative">
                  {" "}
                  <img
                    src= {  playSound   ? "/icons/mute.svg"  :   "/icons/play.svg"}
                    class="ml-3"
                    alt="song"
                    style={{ height: "24px" }}
                    onClick={() => {


                    set_playsound(!playSound)
                    
                    }}
                  />
                </div>
              </div>
            </a>
            
            
            <div class="flex md:order-2">
                {/* <button
                type="button"
                class="underline text-white bg-transparent hover:bg-transparent focus:ring-4 
              focus:outline-none focus:ring-blue-300 font-medium rounded-lg 
              px-4 py-2 text-center mr-3 md:mr-0 dark:bg-transparent dark:hover:bg-blue-700 dark:focus:ring-blue-800"
             
             
             >
                การ์ด


              </button> */}

              <a href="/images/card.png" 
               class="underline text-white bg-transparent hover:bg-transparent focus:ring-4 
               focus:outline-none focus:ring-blue-300 font-medium rounded-lg 
               px-4 py-2 text-center mr-3 md:mr-0 dark:bg-transparent dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              
              
              download> การ์ด</a>


              <button
                data-collapse-toggle="navbar-sticky"
                type="button"
                class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-sticky"
                aria-expanded="false"
                onClick={() => {
                  var element = document.getElementById("navbar-sticky");
                  console.log(element.style.display);
                  element.style.display =
                    element.style.display == "block" ? "" : "block";

                  //   console.log( element.style );

                  //  if(  element.style.display  =="none"){

                  //   element.classList.add("hidden");
                  //  }else{
                  //   element.classList.remove("hidden");

                  //  }
                }}
              >
                <span class="sr-only">Open main menu</span>
                <svg
                  class="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  ></path>
                </svg>
              </button>
            </div>
            


            <div
              class="items-center justify-between  w-full md:flex md:w-auto md:order-1 hidden lg:block"
              id="navbar-sticky"
            >
              <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium md:flex-row md:space-x-8 md:mt-0 md:border-0 ">
                {/* <li>
                <a
                  href="#gallery"
                  class="block py-2 pl-3 pr-4 text-white"
                  aria-current="page"
                >
                  แกลลอรี่
                </a>
              </li> */}

                <li>
                  <a href="#schedule" class="block py-2 pl-3 pr-4 text-white">
                    กำหนดการ
                  </a>
                </li>
                <li>
                  <a href="#map" class="block py-2 pl-3 pr-4 text-white">
                    สถานที่จัดงาน
                  </a>
                </li>

                {/* <li>
                  <a href="#comment" class="block py-2 pl-3 pr-4 text-white">
                    อวยพร
                  </a>
                </li> */}

                
                <li>
                  <a href="#payment" class="block py-2 pl-3 pr-4 text-white">
                    ใส่ซอง
                  </a>
                </li>
              </ul>
            </div>
        
          </div>
        </nav>
        <div>
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/WtcKJtwMD2E?si=HerRwUX3eIb81Jqn&amp;start=55&amp;autoplay=1" title="YouTube video player" allow="autoplay; encrypted-media" allowfullscreen=""></iframe> */}

        <div className="hidden">

          {playSound  &&

        <iframe width="560" height="315" src="https://www.youtube.com/embed/16-wFAbvQiU?si=ngfIqx94bUgXBFL5&amp;start=35&amp;autoplay=1" title="YouTube video player" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>
      }
        </div>
       




 </div>


        <div class=" h-screen relative bg-img" id="#">
          <div
            class="absolute bottom-10 text-title aos-init aos-animate"
            data-aos="fade-down"
          >

            
            <h1
              class="text-white font-semibold text-4xl"
              // style={{ color: "#7E9680" }}

              // style={{ color: "#2C6975" }}

             // style={{ color: "#6096FD" }}
              style={{ color: "#9f705e" }}
            >
              {" "}
              NUTTAWUT &amp; WANTANA
            </h1>
            <p
              class="text-white text-center  font-semibold text-lg"
              style={{ color: "#9f705e" }}
            >
              11 November 2023
            </p>
          </div>
        </div>
        <section class="p-8 text-amber-900">
          <div class="py-4 text-center text-2xl ">
         
            
      


            <div>วันเสาร์ที่ 11 พฤศจิกายน 2566</div>
            <div class="mt-1 flex justify-center" >
            <a  target="_blank" href="https://calendar.google.com/calendar/event?action=TEMPLATE&amp;tmeid=MjJqZTgxcTQ1bWprbjB2ZWpzdmlncmJpajEgZmFtaWx5MTIwMDQ5OTQ2MjQwNTM2NTIxNDlAZw&amp;tmsrc=family12004994624053652149%40group.calendar.google.com"><img border="0" src="https://www.google.com/calendar/images/ext/gc_button1_en-GB.gif"/></a>
            </div>

            
          </div>
{/*           <p class="py-4 text-center text-xl">
            ขอเรียนเชิญแขกผู้มีเกียรติ ญาติผู้ใหญ่ พี่ๆน้องๆ เพื่อนๆ มิตรสหาย
            ที่เคารพรักทุกท่าน
              ร่วมเป็นเกียรติและแสดงความยินดีในงานพิธีมงคลสมรสของ

              
          </p> */}

          <p class="py-4 text-center text-xl">
            ขอเรียนเชิญแขกผู้มีเกียรติ 
            ที่เคารพรักทุกท่าน
            <br></br>
              ร่วมเป็นเกียรติและแสดงความยินดีในงานพิธีมงคลสมรสของ


          </p>
          <p
            class="py-4 text-center text-2xl mt-24 aos-init"
            data-aos="fade-down"
          >
            นาย ณัฐวุฒิ สิทธิเดชบริพัฒน์ (นัท)
          </p>
          <img
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            src="/icons/wedding-rings.svg"
            class="m-auto my-8 aos-init"
            alt=""
            style={{ height: "8rem" }}
          />
          <p class="py-4 text-center text-2xl aos-init" data-aos="fade-up">
            นางสาว วันทนา คำสีทา (สวย)
          </p>
        </section>

        <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8 my-20">
          <div className="w-100 container" id="timeContainer">
            <div className="days">
              <div className="numbers" id="days">
                            </div>
              days   {agoTag}
            </div>
            <div className="days">
              <div className="numbers" id="hours">
              
              </div>
              hours    {agoTag}
            </div>
            <div className="minutes">
              <div className="numbers" id="mins">
               
              </div>
              minutes    {agoTag}
            </div>
            <div className="seconds">
              <div className="numbers" id="secs">
               
              </div>
              seconds    {agoTag}
            </div>
          </div>





          <div id="gallery">
  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 my-16 mx-auto max-w-100">
    <div className="grid gap-4">
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-right"
      >
        <img
      
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/1.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-right"
      >
        <img
     
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/5.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   

    </div>

    <div className="grid gap-4">
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-top"
      >
        <img
         
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/2.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-top"
      >
        <img
         
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/6.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
    </div>

    <div className="grid gap-4">
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-down"
      >
        <img
         
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/3.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-down"
      >
        <img
       
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/7.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
    </div>

    <div className="grid gap-4">
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-left"
      >
        <img
         
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/4.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-left"
      >
        <img
      
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/8.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
    </div>




  </div>
</div>









          <div id="schedule">
            <div className="pt-16 ">
              <ol
                className="items-center my-16 sm:flex"
                style={{ width: "100%" }}
              >
                <li
                  className="relative pt-8 mb-6 sm:mb-0 w-100 aos-init aos-animate"
                  data-aos="fade-up"
                  style={{ width: "100%" }}
                >
                  <div className="flex items-center">
                    <img
                      src="/images/1.svg"
                      alt=""
                      className="m-auto"
                      style={{ height: "200px" }}
                    />
                  </div>
                  <div className="mt-3 sm:pr-8 text-center">
                    <time className="block mb-2 text-2xl font-normal leading-none text-gray-800 dark:text-amber-900">
                      07.09 น.
                    </time>
                    <p className="text-xl font-normal text-amber-900 dark:text-gray-800">
                      พิธีสงฆ์
                    </p>
                  </div>
                </li>
                <li
                  className="relative pt-8 mb-6 sm:mb-0 w-100 aos-init aos-animate"
                  data-aos="fade-up"
                  style={{ width: "100%" }}
                >
                  <div className="flex items-center">
                    <img
                      src="/images/2.svg"
                      alt=""
                      className="m-auto"
                      style={{ height: "200px" }}
                    />
                  </div>
                  <div className="mt-3 sm:pr-8 text-center">
                    <time className="block mb-2 text-2xl font-normal leading-none text-gray-800 dark:text-amber-900">
                      08.09 น.
                    </time>
                    <p className="text-xl font-normal text-amber-900 dark:text-gray-800">
                      พิธีแห่ขันหมาก
                    </p>
                  </div>
                </li>
                <li
                  className="relative pt-8 mb-6 sm:mb-0 w-100 aos-init aos-animate"
                  data-aos="fade-up"
                  style={{ width: "100%" }}
                >
                  <div className="flex items-center m-auto ">
                    <img
                      src="../images/3.svg"
                      alt=""
                      className="m-auto"
                      style={{ height: "200px" }}
                    />
                  </div>
                  <div className="mt-3 sm:pr-8 text-center">
                    <time className="block mb-2 text-2xl font-normal leading-none text-gray-800 dark:text-amber-900">
                      09.09 น.
                    </time>
                    <p className="text-xl font-normal text-amber-900 dark:text-gray-800">
                      หลั่งน้ำพระพุทธมนต์
                    </p>
                  </div>
                </li>
                <li
                  className="relative pt-8 mb-6 sm:mb-0 aos-init aos-animate"
                  data-aos="fade-up"
                  style={{ width: "100%" }}
                >
                  <div className="flex items-center m-auto">
                    <img
                      src="../images/4.svg"
                      alt=""
                      className="m-auto"
                      style={{ height: "200px" }}
                    />
                  </div>
                  <div className="mt-3 sm:pr-8 text-center">
                    <time className="block mb-2 text-2xl font-normal leading-none text-gray-800 dark:text-amber-900">
                      11.09 น.
                    </time>
                    <p className="text-xl font-normal text-amber-900 dark:text-gray-800">
                      ร่วมรับประทานอาหาร (โต๊ะจีน)
                    </p>
                  </div>
                </li>
              </ol>
              <div
                className="my-32 py-16 aos-init aos-animate"
                data-aos="fade-down"
              >
                <h1 className="text-xl font-semibold text-amber-900 sans">
                  ธีม
                </h1>
                <div className="flex items-center flex-nowrap flex-row pt-10 my-8">
                  <div className="basis-1/5">
                    <div
                      className="rounded-full w-16 h-16 m-auto"
                      style={{ backgroundColor: "rgba(185,177,166,255)" }}
                    />
                  </div>
                  <div className="basis-1/5">
                    <div
                      className="rounded-full w-16 h-16 m-auto"
                      style={{ backgroundColor: "rgba(223,188,158,255)" }}
                    />
                  </div>
                  <div className="basis-1/5">
                    <div
                      className="rounded-full w-16 h-16  m-auto"
                      style={{ backgroundColor: "rgba(252,170,96,255)" }}
                    />
                  </div>
                  <div className="basis-1/5">
                    <div
                      className="rounded-full w-16 h-16  m-auto"
                      style={{ backgroundColor: "rgba(249,187,140,255)" }}
                    />
                  </div>

                  <div className="basis-1/5">
                    <div
                      className="rounded-full w-16 h-16  m-auto"
                      style={{ backgroundColor: "rgba(245,122,140,255)" }}
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <h1 className="text-xl font-semibold text-amber-900 sans">
              สถานที่จัดงาน
            </h1>
            <br/>

            <h1 className="text-lg font-semibold text-amber-900 sans">
              อาคาร ถกลพระเกียรติ สวนหลวง ร.9 ประตู 4 ดาวเรือง (ตรงข้าม บ.พัฒน์กล)
            </h1>
            <div id="map" className="flex items-center w-100">
              <div className="google-map-code my-16 m-auto">
                <iframe
                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.475753441554!2d100.66355577508916!3d13.689610986695797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d6181ef012169%3A0xb262ed80173dbfc0!2z4Lit4Liy4LiE4Liy4Lij4LiW4LiB4Lil4Lie4Lij4Liw4LmA4LiB4Li14Lii4Lij4LiV4Li0IOC4quC4p-C4meC4q-C4peC4p-C4hyDguKMuOQ!5e0!3m2!1sth!2sth!4v1697946962654!5m2!1sth!2sth"
                  className="map"
                  height={450}
                  frameBorder={0}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                  style={{ border: "0px" }}
                />
              </div>{" "}
            </div>
          </div>

          
        {/*   <h1
            className="text-xl text-amber-950 text-center mb-16 sans"
            id="comment"
          >
            อวยพร
          </h1>
          <div className="flex items-center w-100">
            <div className="m-auto">
              <div
                className="fb-comments w-100 fb_iframe_widget fb_iframe_widget_fluid_desktop"
                data-href="https://wedding-web-lime.vercel.app/"
                data-width={425}
                data-numposts={5}
                fb-xfbml-state="rendered"
                fb-iframe-plugin-query="app_id=342214374546756&container_width=0&height=100&href=https%3A%2F%2Fwedding-web-lime.vercel.app%2F&locale=th_TH&numposts=5&sdk=joey&version=v18.0&width=425"
              >
                <span
                  style={{
                    verticalAlign: "bottom",
                    width: "425px",
                    height: "816px",
                  }}
                >
                  <iframe
                    name="f20aab8985f73ec"
                    width="425px"
                    height="100px"
                    data-testid="fb:comments Facebook Social Plugin"
                    title="fb:comments Facebook Social Plugin"
                    frameBorder={0}
                    allowTransparency="true"
                    allowFullScreen="true"
                    scrolling="no"
                    allow="encrypted-media"
                    src="https://www.facebook.com/v18.0/plugins/comments.php?app_id=342214374546756&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df14a73b2723a574%26domain%3Dwww.teekawat-plaifon.online%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.teekawat-plaifon.online%252Ff38222c0f62cfa4%26relation%3Dparent.parent&container_width=0&height=100&href=https%3A%2F%2Fwedding-web-lime.vercel.app%2F&locale=th_TH&numposts=5&sdk=joey&version=v18.0&width=425"
                    style={{
                      border: "none",
                      visibility: "visible",
                      width: "425px",
                      height: "816px",
                    }}
                    className
                  />
                </span>
              </div>
            </div>
        
        
          </div> */}
          
          <br/>
          <h1 className="text-xl font-semibold text-amber-900 sans">
              ใส่ซอง
            </h1>
         
          <div className="image-container qr m-auto">
            <img
              alt="payment"
              id="payment"
              data-aos="fade-up"
              loading="lazy"
              decoding="async"
              data-nimg="fill"
              className="mt-16 text-title image aos-init"
              sizes="100vw"

              // srcSet="/_next/image?url=%2Fimages%2Fqr.jpg&w=640&q=75 640w, /_next/image?url=%2Fimages%2Fqr.jpg&w=750&q=75 750w, /_next/image?url=%2Fimages%2Fqr.jpg&w=828&q=75 828w, /_next/image?url=%2Fimages%2Fqr.jpg&w=1080&q=75 1080w, /_next/image?url=%2Fimages%2Fqr.jpg&w=1200&q=75 1200w, /_next/image?url=%2Fimages%2Fqr.jpg&w=1920&q=75 1920w, /_next/image?url=%2Fimages%2Fqr.jpg&w=2048&q=75 2048w, /_next/image?url=%2Fimages%2Fqr.jpg&w=3840&q=75 3840w"

             


              src= "/images/qr.jpg"

              style={{
                // position: "absolute",
                height: "100%",
                width: "100%",
                inset: "0px",
                color: "transparent",
              }}

            />
          </div>
          <br/>  <br/> <br/>
          <h1 className="text-2xl text-amber-950 text-center">ขออภัยหากมิได้เรียนเชิญด้วยตนเอง</h1>

          <br/>  <br/> <br/><br/><br/>
          <div className="text-center" >
          <a target="_blank" href="https://www.facebook.com/profile.php?id=61553050132661" 
               className="underline"
              
              
              > create by รับจัดทำการ์ดงานแต่ง .. ออนไลน์</a>
</div>
          <br/> <br/>

          
        </div>
      </main>
    </>
  );
}

export default App;
